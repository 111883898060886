@import "~variables.scss";
.wrap {
  width: 296px;
  border-radius: 12px;
  background-color: #ffffff;
  .title {
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    color: #000000;
    padding-top: 24px;
  }
  .content {
    text-align: center;
    font-size: 14px;
    line-height: 1.7;
    padding: 8px 40px 24px 40px;
    box-sizing: border-box;
  }
  .footerWrap {
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    display: flex;
    .cancalBtn, .okBtn {
      flex: 1;
      font-size: 16px;
      color: #000000;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .okBtn {
      color: $main-color;
      font-weight: bold;
      &.alert {
        color: #000000;
      }
      &.loading {
        color: rgba(0, 0, 0, 0.3);
      }
    }
    .cancalBtn {
      border-right: 1px solid rgba(0, 0, 0, 0.04);
      &.loading {
        color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}
