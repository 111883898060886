@import "~normalize.css";
@import "~variables.scss";

html {
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  font-size: $font-size;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "PingFang SC","Helvetica Neue",STHeiti,"Microsoft Yahei",Tahoma,Simsun,sans-serif;
  width: $page-width;
  margin: 0 auto;
}
body::-webkit-scrollbar {
  display: none;
}
.default-transition-opacity-enter {
  opacity: 0;
}
.default-transition-opacity-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.default-transition-opacity-exit {
  opacity: 1;
}
.default-transition-opacity-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.defaultScrollbar::-webkit-scrollbar {
  width:4px;
  border-radius:4px;
}
.defaultScrollbar::-webkit-scrollbar-track {
  border-radius:4px;
  background: rgba(0, 0, 0, 0.1);
}
.defaultScrollbar::-webkit-scrollbar-thumb {
  border-radius:4px;
  background: $main-color;
}
