.spinning-enter {
  opacity: 0;
  transform: scale(0.9);
}
.spinning-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.spinning-exit {
  opacity: 1;
}
.spinning-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.wrap {
  position: relative;
  .inContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
.tipWrap {
  display: inline-block;
  .tipContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 114px;
    height: 114px;
    &.tipContentBorder {
      border-radius: 8px;
      box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.12);
      background-color: #ffffff;
    }
  }
}
.tip {
  color: rgba(0, 0, 0, 0.3);
  font-size: 13px;
  text-align: center;
  margin-top: 8px;
}
