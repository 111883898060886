@import "../../mixins.scss";
@import "./variables.scss";
@import "./font/teki.css";

.homepage {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(169deg, rgba(255, 235, 222, .75), rgba(255, 243, 234, .75));
  padding-top: 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 0;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 150px;
    background: url("./image/backgroundTexture.png") 0 0 / 100% 724px;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .logo {
    width: 118px;
    height: 41px;
    @include fullBg(url("./image/logo.png"));
    margin: 0 auto 20px;
  }
  .download-btn {
    position: fixed;
    z-index: 5;
    bottom: 36px;
    left: 50%;
    transform: translateX(-50%);
    width: 226px;
    height: 72px;
    @include fullBg(url("./image/downloadBtn.png"));
  }
  .footer {
    width: 375px;
    margin: 60px auto 0;
    & > p {
      height: 17px;
      line-height: 17px;
      line-height: normal;
      font-size: 10px;
      text-align: center;
      color: $mainTextColor;
      & > a.text {
        text-decoration: none;
        color: $mainTextColor;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.download-dialog {
  width: 200px;
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  color: #333;
  .download-dialog-content {
    padding: 24px;
    line-height: 20px;
    font-size: 16px;
  }
  .download-dialog-footer {
    border-top: 1px solid #ccc;
    box-sizing: border-box;
    height: 50px;
    line-height: 50px;
  }
}
