@import './common.scss';
@mixin mask-effect {
  // animation-duration: 0.3s;
  animation-fill-mode: both;
  // animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
}
.#{$prefixCls} {
  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    z-index: 1050;

    &-hidden {
      display: none;
    }
  }

  // &-mask-enter, &-mask-appear {
  //   opacity: 0;
  //   @include mask-effect();
  //   animation-play-state: paused;
  // }

  // &-mask-leave {
  //   @include mask-effect();
  //   animation-play-state: paused;
  // }

  &.#{$prefixCls}-mask-enter.#{$prefixCls}-mask-enter-active, &.#{$prefixCls}-mask-appear.#{$prefixCls}-mask-appear-active  {
    animation-name: maskIn;
    animation-play-state: running;
  }

  &.#{$prefixCls}-mask-leave.#{$prefixCls}-mask-leave-active {
    animation-name: maskOut;
    animation-play-state: running;
  }

  @keyframes maskIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes maskOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
