@import "~variables.scss";

.cs-scroll {
  > .cs-scroll-dot-bar {
    width: 60%;
    max-width: 8rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: $gutter-l;
    height: 4px;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    overflow: hidden;
    > .cs-scroll-dot {
      height: 100%;
      border-radius: 3px;
      background: $background-color-primary;
      transition: all 0.3s;
    }
  }
}
