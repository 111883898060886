// 页面宽度
$page-width: 375px;

// 默认字体行高
$font-line-height: 1.12;

// 等宽字体
$font-family-mono: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;

// 文字大小
$font-size-xs: 11px;
$font-size-s: 13px;
$font-size: 16px;     // 全局字体大小
$font-size-l: 20px;
$font-size-xl: 28px;

// 颜色
$main-color: #fe5353;
$background-color: #fff;
$background-color-primary: linear-gradient(to right, #FE5090, #FE5353);
$font-color: #000;
$font-color-primary: $main-color;

// 间隔
$gutter-xs: 8px;
$gutter-s: 12px;
$gutter: 16px;
$gutter-l: 24px;
$gutter-xl: 32px;

// 头像
$avatar-size-default: 88px;
$avatar-size-large: 110px;
$avatar-badge-size-percent: 30%;

// 页面底部留白高度
$page-padding-bottom: 80px;
