
.carouselContainer {
  width: 100%;
  margin: 0 auto;
  :global {
    .cs-scroll {
      > .cs-scroll-content.swiper-container {
        overflow: visible !important;
      }
    }
  }

  .indicatorWrapper {
    height: 5px;
    box-sizing: border-box;
    margin: 21px auto 0;
    justify-content: space-between;
    display: flex;
    .indicator {
      width: 8px;
      height: 5px;
      background: rgba(255, 255, 255, 1);
      border-radius: 3px;
      &.active {
        width: 20px;
        background: rgba(255, 204, 172, 1);
      }
    }
  }
}
