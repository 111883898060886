@import './common.scss';
@mixin effect {
  // animation-duration: 1.3s;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
@mixin slideUpEffect {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(.55, 0, .55, .3);
}
.#{$prefixCls} {
  position: relative;
  &.bottom {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%)
  }
  &-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
      // 默认会居中再往上一点
      .#{$prefixCls}-content {
        margin-top: -50px;
      }
    }
  }

  &-close {
    width: 16px;
    height: 16px;
    background: url('../imgs/close.png') no-repeat;
    background-size: 16px 16px;
    position: absolute;
    right: 12px;
    top: 16px;
    padding: 0;
    border: none;
    outline: none;
  }


  // 默认 渐显动画
  &-dialog-enter, &-dialog-appear {
    opacity: 0;
    @include effect();
    animation-play-state: paused;
  }

  &-dialog-leave {
    @include effect();
    animation-play-state: paused;
  }

  &.#{$prefixCls}-dialog-enter.#{$prefixCls}-dialog-enter-active, &.#{$prefixCls}-dialog-appear.#{$prefixCls}-dialog-appear-active {
    animation-name: dialogIn;
    animation-play-state: running;
  }

  &.#{$prefixCls}-dialog-leave.#{$prefixCls}-dialog-leave-active {
    animation-name: dialogOut;
    animation-play-state: running;
  }

  @keyframes dialogIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes dialogOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  // 从下往上推动画
  &-slideUp-enter, &-slideUp-appear {
    opacity: 0;
    @include slideUpEffect();
    animation-play-state: paused;
  }

  &-slideUp-leave {
    @include slideUpEffect();
    animation-play-state: paused;
  }
  &.#{$prefixCls}-slideUp-enter.#{$prefixCls}-slideUp-enter-active, &.#{$prefixCls}-slideUp-appear.#{$prefixCls}-slideUp-appear-active {
    animation-name: slideUpIn;
    animation-play-state: running;
  }

  &.#{$prefixCls}-slideUp-leave.#{$prefixCls}-slideUp-leave-active {
    animation-name: slideUpOut;
    animation-play-state: running;
  }

  @keyframes slideUpIn {
    0% {
      opacity: 0;
      bottom: -100%;
    }
    100% {
      opacity: 1;
      bottom: 0;
    }
  }
  @keyframes slideUpOut {
    0% {
      bottom: 0;
    }
    100% {
      bottom: -100%;
    }
  }
}



