@import "../../variables.scss";
@import "../../../../mixins.scss";

.cardContainer {
  width: 375px;
  position: relative;
  .desc {
    width: 100%;
    height: 28px;
    margin: 28px auto 20px;
    font-size: 20px;
    color: $mainTextColor;
    line-height: 28px;
    // font-weight: 500;
    white-space: pre-line;
    text-align: center;
  }
  .imgWrapper {
    width: 216px;
    height: 364px;
    background-color: rgba(255,255,255,0.5);
    box-shadow: 0px 15px 33px 0px rgba(233,202,182,0.63);
    border-radius: 15px;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    & > img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 203px;
      height: 351px;
      z-index: 1;
    }
    .playerDecorationWrapper {
      position: absolute;
      top: 94px;
      left: -52px;
      width: 110px;
      height: 49px;
      @include fullBg(url(@/apps/Homepage/image/shadow.png));
      .point {
        position: absolute;
        top: -6px;
        right: 1px;
        width: 14px;
        height: 14px;
        @include fullBg(url(@/apps/Homepage/image/point.png));
        z-index: 1;
      }

      .playerDecoration {
        position: absolute;
        top: 0px;
        left: 8px;
        width: 92px;
        height: 25px;
        background: rgba(148,62,0,1);
        border-radius: 13px 0 13px 13px;
        border: 1px solid rgba(255,255,255,0.93);
        z-index: 1;
        .inner {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 61px;
          height: 35px;
          @include fullBg(url(@/apps/Homepage/image/playerInner.png));
        }
        .duration {
          position: absolute;
          right: 4px;
          top: 5px;
          width: 40px;
          height: 16px;
          line-height: 16px;
          font-size: 16px;
          color: rgba(255, 255, 255, .9);
          text-align: right;
          font-family: teki;
        }
      }
    }
    .textDecoration {
      position: absolute;
      top: 214px;
      right: -41px;
      width: 104px;
      height: 22px;
      font-size: 10px;
      background: rgba(247, 186, 126, 1);
      color: rgba(255, 245, 237, 1);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
    }
    .mountainDecoration {
      position: absolute;
      width: 86px;
      height: 22px;
      @include fullBg(url(@/apps/Homepage/image/mountain.png));
      left: -62px;
      bottom: 0;
      z-index: 3;
    }
  }
}
