.default {
  width: 32px;
  height: 32px;
}
.small {
  width: 20px;
  height: 20px;
}
.staticFuntionWrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
